import React from 'react';
import WareHouseSales from './WareHouseSales';
import InvoiceSummaryHistory from './InvoiceSummaryHistory';
import { CardTitle, ListGroup } from 'react-bootstrap';
import { ListGroupItem } from 'react-bootstrap';
import WareHouse from './WareHouse';
import { Card} from 'react-bootstrap';

class SingleWarehouse extends React.Component {
  

  render() {
      let { warehouseId } = this.props.match.params;
      // for dashboard to work
      if( !warehouseId){
        warehouseId = 'Foulger';
      }
      return (
          <Card>
            <CardTitle>{warehouseId}</CardTitle>
              <WareHouse {...this.props} warehouseId={warehouseId} single={true} />

              <InvoiceSummaryHistory warehouse={warehouseId} />
              <WareHouseSales title={warehouseId} contactName={warehouseId} months='3' />
                      <ListGroup>
                  <ListGroupItem action href={'/view/' + warehouseId + '/tran'}>View WarehouseTransactions</ListGroupItem>
                 </ListGroup>
          </Card>
      )
    }
}

export default SingleWarehouse;
