import React from 'react';
import SalesTopX from './SalesTopX';
import { Card, CardTitle} from 'react-bootstrap';
import DateFnsFormat from 'date-fns/format';
import TotalSales from './TotalSales';
import WareHouseSales from './WareHouseSales';


class CustomerInsights extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    
    const groupURL = "bill/sales/groups/2024-01-01/" + DateFnsFormat(new Date(), 'yyyy-MM-dd') + "/30"; 
    const  garageUrl = "bill/sales/warehouse/Garage/2024-01-01/" + DateFnsFormat(new Date(), 'yyyy-MM-dd') + "/100"; 
   
    return(
        <Card className="CustomerInsights 2024"   header="Customer Insights">
        <CardTitle>Customer Insights</CardTitle>
          <TotalSales id="all" title="All"/>
          <TotalSales id="notbrakebidfood" title="Brakes and Bidfood Excluded" />
          <SalesTopX url={groupURL} title="Top Customers" />
           <SalesTopX url={garageUrl} title="Garage Customers" /> 
           <WareHouseSales contactName="all" title="All Customers" months="6"/>
           <WareHouseSales contactName="notbrakebidfood" title="Not Brakes and Bidfood" months="6"/>
    
        </Card>
      )
    }
  
}

export default CustomerInsights;
