import React from "react";

import { useFetch } from '../services/useFetch';
import { Card, CardTitle } from 'react-bootstrap';
import { serverURL } from "../services/api";
import { getGraphSeries, mergeGraphSeries } from "../services/util";
import { AgCharts } from 'ag-charts-react';


export default function PackingCostsVsUnitsShippedMoM(id) {
  const [pckunits1] = useFetch(serverURL + "bill/packvsunits/mom/2022-01-01/2022-12-31");
  const [pckunits2] = useFetch(serverURL + "bill/packvsunits/mom/2023-01-01/2023-12-31");
  const [pckunits3] = useFetch(serverURL + "bill/packvsunits/mom/2024-01-01/2024-12-31");
  const [pckunits4] = useFetch(serverURL + "bill/packvsunits/mom/FDC/2023-01-01/2023-12-31");
  const [pckunits5] = useFetch(serverURL + "bill/packvsunits/mom/FDC/2024-01-01/2024-12-31");
  const [pckunits6] = useFetch(serverURL + "bill/packvsunits/mom/FDC/2025-01-01/2025-12-31");

  if (!pckunits1 || !pckunits2 || !pckunits3 || !pckunits4 || !pckunits5 || !pckunits6) {
    return (
      <Card className="foulger">
        <CardTitle>{"Packing Cost per Unit Shipped"}</CardTitle>
        <div className="loader">Loading...</div>
      </Card>
    )
  }
  else {


    let fdcSeries = getGraphSeries(pckunits4.monthList);
    fdcSeries = mergeGraphSeries(fdcSeries, pckunits5.monthList, 1);
    fdcSeries = mergeGraphSeries(fdcSeries, pckunits6.monthList, 2);
    let foulgerSeries = getGraphSeries(pckunits1.monthList);
    foulgerSeries = mergeGraphSeries(foulgerSeries, pckunits2.monthList, 1);
    foulgerSeries = mergeGraphSeries(foulgerSeries, pckunits3.monthList, 2);



    const fdcOptions = {
      data: fdcSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2023",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y1",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2025",
          yKey: "y2",
        }
      ],
      title: { text: "Packing Cost per Unit Shipped From FDC" }

    };

    const foulgerOptions = {
      data: foulgerSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2022",
        },
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2023",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y1",
        }
      ],
      title: { text: "Packing Cost per Unit Shipped From Foulger" }
    };



    return (
      <Card className="foulger">
        <div><AgCharts options={fdcOptions} /></div>
        <div>FDC Packing Cost per case 2023: {pckunits4.totalRatio} </div>
        <div>FDC Packing Cost per case 2024: {pckunits5.totalRatio} </div>
        <div>FDC Packing Cost per case 2025: {pckunits6.totalRatio} </div>

        <div><AgCharts options={foulgerOptions} /></div>
        <div>Foulger Packing Cost per case 2022: {pckunits1.totalRatio} </div>
        <div>Foulger Packing Cost per case 2023: {pckunits2.totalRatio} </div>
        <div>Foulger Packing Cost per case 2024: {pckunits3.totalRatio} </div>
      </Card>

    );
  }
}