import React from 'react';
import { Card } from 'react-bootstrap';
import ProfitFunction from './ProfitFunction';


class CustomerProductBreakdown extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Card className="CustomerInsights"   header="Customer Product Breakdown">
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='2021' /></div>
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='2022' /></div>
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='2023' /></div>
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='2024' /></div>
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='2025' /></div>

    </Card>
    )
  }

}

export default CustomerProductBreakdown;
