import React from 'react';
import { CardTitle, Table } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
var moment = require('moment');

import * as utilFunctions from '../services/util';

function RenderProduct(data, i) {

  return (<tr className="details-row" key={i}>
    <td className="inv-head-med">{data.product.prodCode}</td>
    <td className="inv-head-med">{data.soldValue}</td>
    <td className="inv-head-med">{data.productCost}</td>
    <td className="inv-head-med">{data.unitsSold}</td>
    <td className="inv-head-med">{utilFunctions.round2money(data.precentageOfSales * 100)}%</td>
    <td className="inv-head-med">{utilFunctions.round2money(data.productMargin * 100)}%</td>
  </tr>)
};

export default function WareHouseSales(props) {
  var now = moment();
  const months = props.months; 

  const [profit] = useFetch(serverURL + "bill/whsales/" + props.contactName + "/" + now.subtract(months, 'months').format("YYYY-MM-DD"));


  console.log('profit call: ' + JSON.stringify(profit));
  if (!profit || profit == undefined ) {
    return (
      <Card className="foulger" >
        <CardTitle>{props.title + " Sales and Profit Last " + months + " Months"}</CardTitle>
        <div className="loader">Loading...</div>
      </Card>
    )
  }
  else {
    let cost = 'not used'; 
    let salesValue ='not used'; 
    let sortedProfit = profit.productData; 
    if( profit.productData != null && profit.productData.length > 0  ) {
      cost = profit.productData[0].unitProductCost;
      salesValue = profit.productData[0].unitProductSalesValue;
      sortedProfit = profit.productData.sort((p1, p2) => (p1.unitsSold > p2.unitsSold) ? -1 : (p1.unitsSold < p2.unitsSold) ? 1 : 0);
    }else{ 
      return (
             <Card>
               <CardTitle>{props.title + " Sales and Profit Last " + months + " Months"}</CardTitle>
                  <div>Data failed to fetch correctly</div>
            </Card>

      )
    }
    return (
      <Card>
        <CardTitle>{props.title + " Sales and Profit Last " + months + " Months"}</CardTitle>
         <div>{"Product Sales Value: " + salesValue}</div>
        <div>{"Product Cost: " + cost}</div>
        <div>{"Storage Cost " + profit.storageCostUnit}</div>
        <div>{"Shipping Cost " + profit.shippingCostUnit}</div>
        <Table className="inv-table profit-table" bordered hover>
          <thead>
            <tr className="profit-head">
              <th className="inv-head-med">Product</th>
              <th className="inv-head-med">Sales</th>
              <th className="inv-head-med">Cost</th>
              <th className="inv-head-med">Quantity</th>
              <th className="inv-head-med">Precentage</th>
              <th className="inv-head-med">Margin</th>
            </tr>
          </thead>
          <tbody>
            <tr className="summary-row">
              <td className="inv-head-med">Totals</td>
              <td className="inv-head-med">{profit.totalSales}</td>
              <td className="inv-head-med">{profit.totalCost}</td>
              <td className="inv-head-med">{profit.totalUnits}</td>
              <td className="inv-head-med">{"N/A"}</td>
              <td className="inv-head-med">{utilFunctions.round2money(profit.productMargin * 100)}%</td>
            </tr>
            {sortedProfit.map(RenderProduct)}
          </tbody>
        </Table>
      </Card>

    )
  }
}

