import React, { useState, useEffect } from "react";

import { useFetch } from '../services/useFetch';
import { Card, CardTitle} from 'react-bootstrap';
import { serverURL } from "../services/api";
import { getGraphSeries, mergeGraphSeries} from "../services/util"; 
import { AgCharts } from 'ag-charts-react';



export default function PackingCostsMoM(id) {
  const [data] = useFetch(serverURL + "bill/packing/mom/2023-01-01/2023-12-31");
  const [data2] = useFetch(serverURL + "bill/packing/mom/2024-01-01/2024-12-31");
  const [data3] = useFetch(serverURL + "bill/packing/mom/FDC/2023-01-01/2023-12-31");
  const [data4] = useFetch(serverURL + "bill/packing/mom/FDC/2024-01-01/2024-12-31");
  const [data5] = useFetch(serverURL + "bill/packing/mom/FDC/2025-01-01/2025-12-31");

  //const [data3] = useFetch( {serverURL} + "bill/packing/wow/2022-01-09/2022-09-30");
 
  if( !data || !data2 || !data3 || !data4 || !data5){
    return (
      <Card className="foulger" >
        <CardTitle>{"Packing cost per order MoM"}</CardTitle>
       <div className="loader">Loading...</div>
      </Card>
    )
  }
  else{

    let foulgerSeries = getGraphSeries(data.monthList);
    foulgerSeries = mergeGraphSeries(foulgerSeries, data2.monthList, 1);
    let costSeries = getGraphSeries(data3.monthList);
    costSeries = mergeGraphSeries(costSeries, data4.monthList, 1);
    costSeries = mergeGraphSeries(costSeries, data5.monthList, 2);



    const fdcOptions = {
      data: costSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2023",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y1",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2025",
          yKey: "y2",
        }
      ],
      title: { text: "Packing Cost per order Shipped From FDC" }

    };

    const foulgerOptions = {
      data: foulgerSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2023",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y1",
        }
      ],
      title: { text: "Packing Cost per order Shipped From Foulger" }

    };




  return (
    <Card className="foulger">
    <div>    <AgCharts options={fdcOptions} /></div>
    <div>FDC Cost per order 2023: {data3.totalRatio} </div>
    <div>FDC Cost per order 2024: {data4.totalRatio} </div>
    <div>FDC Cost per order 2024: {data5.totalRatio} </div>
    <div>    <AgCharts options={foulgerOptions} /></div>
    <div>Foulger Cost per order 2023: {data.totalRatio} </div>
    <div>Foulger Cost per order 2024: {data2.totalRatio} </div>
    </Card>
  );
}
}