import React from 'react';
import { Panel} from 'react-bootstrap';
import { FormGroup} from 'react-bootstrap';
import { Checkbox} from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

class StatementEntry extends React.Component {
  printBoolean(transaction){
    if( transaction.reconciled ){
      return "yes";
    }
    else{
      return "no";
    }
  };

  render() {
  const {transaction, i, handleDelete,handleUnreconcile , handleReconcile} = this.props;

  return(
  <tr key={i}>
        <td className="tran-head-med">{transaction.warehouse}</td>
        <td className="tran-head-med">{transaction.type}</td>
        <td className="tran-head-med">{DateFnsFormat(parseISO(transaction.transactionDate), 'dd-MM-yyyy')}</td>
        <td className="tran-head-med">{transaction.prodCode}</td>
        <td className="tran-head-med">{transaction.reference}</td>
        <td className="tran-head-med">{transaction.customerReference}</td>
        <td className="tran-head-med">{transaction.batch}</td>
        <td className="tran-head-med">{transaction.quantity}</td>
        <td className="tran-head-med">{this.printBoolean(transaction)}</td>
        <td className="tran-head-med">{transaction.inventoryTransactionId}</td>
        <td hidden={transaction.reconciled} className="tran-head-del"><Button
            bsSize="xsmall"
            onClick={handleReconcile}
          >Reconcile</Button></td>
        <td hidden={!transaction.reconciled} className="tran-head-del"><Button
            bsSize="xsmall"
            onClick={handleUnreconcile}
          >UnReconcile</Button></td>
    </tr>
  )
  }
}

class StatementHistory extends React.Component {
    constructor (props) {
      super(props)
      this.handleToChange = this.handleToChange.bind(this);
      this.handleFromChange = this.handleFromChange.bind(this);
      this.handleTypeChange = this.handleTypeChange.bind(this);

      this.state = {
          type: (this.props.statements.option == 'unrec' ? 'unrec':'all')
        };

    }
    handleTypeChange = (e) =>{
        console.log(e.target.checked);
        if( e.target.checked ){
          this.setState({type: 'unrec'});
        }else {
          this.setState({type: 'all'});
        }
    }
    createDate = (date) =>{
      console.log('date: ' + date  )
       if( date == 'na'){
         return null;
      }else{
        return new Date(parseISO(date));
      }
    }

    handleFromChange(date) {
      this.props.fetchStatementsBetweenDates(this.props.id, DateFnsFormat(date, 'yyyy-MM-dd'), this.props.statements.toDate, this.state.type);

    }

    handleToChange(date) {
      this.props.fetchStatementsBetweenDates(this.props.id, this.props.statements.fromDate, DateFnsFormat(date, 'yyyy-MM-dd'),this.state.type)
    }

    handleUnreconcile(id, index, e){
      console.log( 'handleUnreconcile statement' + id + ' index' + index);
      this.props.unreconcile('statement', id, index);
    };

    handleReconcile(id, index, e){
      console.log( 'handleReconcile statement ' + id + ' index' + index);
      this.props.reconcileSingle('statement', id, index);
    };

  render() {
    const { hidden, statements, id , fetchStatementsBetweenDates , reconcileSingle, unreconcileTransaction} = this.props;
    const checked = (this.state.type === 'unrec' ? 'checked':'');
    if( hidden ){
      return (<div/>)
    }else{
    if( statements.isFetching){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header="Statement Transactions">
         <div className="loader">Loading...</div>
        </Panel>
      )
    }
    else{
      return(
        <Panel className="dashboard" collapsible defaultExpanded header="Statement Transactions">
        <div className="inv-top inv-date"><p>Transaction range: </p></div>
        <div className="inv-top inv-date"><p>from:</p>
        <DatePicker
                          selected={this.createDate(statements.fromDate)}
                          onChange={this.handleFromChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div className="inv-top inv-due"><p>to:</p>
        <DatePicker
                          selected={this.createDate(statements.toDate)}
                          onChange={this.handleToChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div className="inv-top inv-due"><p>show:</p>
          <FormGroup onChange={this.handleTypeChange}>
              <Checkbox checked={this.state.type=='unrec'?true:false} inline>Show only un-reconciled</Checkbox>
          </FormGroup>
        </div>
        <Table className="inv-table" striped bordered condensed hover>
          <thead>
            <tr>
            <th className="tran-head-med">Warehouse</th>
            <th className="tran-head-med">Type</th>
            <th className="tran-head-med">Date</th>
            <th className="tran-head-med">Product</th>
            <th className="tran-head-med">Ref</th>
            <th className="tran-head-med">Cust Ref</th>
            <th className="tran-head-med">Batch</th>
            <th className="tran-head-med">Num</th>
            <th className="tran-head-med">Rec</th>
            <th className="tran-head-med">Rec Ref</th>
            </tr>
          </thead>
          <tbody>
          {
            statements.lists.map((transaction, i) => <StatementEntry
                                                        handleUnreconcile={this.handleUnreconcile.bind(this, transaction.id, i)}
                                                        handleReconcile={this.handleReconcile.bind(this, transaction.id, i)}
                                                        key={i} i={i} transaction={transaction} />)
          }
          </tbody>
        </Table>
        </Panel>
      )
    }

  }
}
}

export default StatementHistory;
