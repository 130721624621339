import React from 'react';
import { Table } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
var moment = require('moment');

import * as utilFunctions from '../services/util';

function RenderProduct(data, i) {

  return (<tr className="details-row" key={i}>
    <td className="inv-head-med">{data.product.prodCode}</td>
    <td className="inv-head-med">{data.soldValue}</td>
    <td className="inv-head-med">{data.productCost}</td>
    <td className="inv-head-med">{data.unitsSold}</td>
    <td className="inv-head-med">{utilFunctions.round2money(data.precentageOfSales * 100)}%</td>
    <td className="inv-head-med">{utilFunctions.round2money(data.productMargin * 100)}%</td>
  </tr>)
};

export default function ProfitFunction(props) {
  var now = moment();
  const months = props.months; 
  var url  = null; 
  var header = "Sales and Profit " + months; 
  if (months == 2020) { 
    url = serverURL + "bill/profit/" + props.contactName + "/2020-01-01/2020-12-31";
  }else if ( months == 2021){
    url  = serverURL + "bill/profit/" + props.contactName + "/2021-01-01/2021-12-31";

  }else if ( months == 2022){
    url  = serverURL + "bill/profit/" + props.contactName + "/2022-01-01/2022-12-31";

  }else if ( months == 2023 ) {
    url = serverURL + "bill/profit/" + props.contactName + "/2023-01-01/2023-12-31";

  }else if ( months == 2024 ) {
    url= serverURL + "bill/profit/" + props.contactName+ "/2024-01-01/2024-12-31";

  }else{  
    header = "Sales and Profit Last " + months + " Months"
      url = serverURL + "bill/profit/" + props.contactName + "/" + now.subtract(months, 'months').format("YYYY-MM-DD");
  }
  const [profit] = useFetch(url); 

  console.log(JSON.stringify(profit));
  if (!profit) {
    return (
      <Panel className="foulger" collapsible defaultExpanded header={props.title}>
        <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else {
    let cost = 'n/a'; 
    let salesValue = 'n/a'; 
    let sortedProfit = profit.productData; 
    if( profit.productData != null && profit.productData.length > 0 ) {
      cost = utilFunctions.round2money(profit.totalCost/profit.totalUnits - profit.storageCostUnit - profit.shippingCostUnit); 
      salesValue = profit.productData[0].unitProductSalesValue;
      sortedProfit = profit.productData.sort((p1, p2) => (p1.unitsSold > p2.unitsSold) ? -1 : (p1.unitsSold < p2.unitsSold) ? 1 : 0);

    }
    return (
      <Panel header={header}>
         <div>{"Product Sales Value: " + salesValue}</div>
        <div>{"Product Cost: " + cost}</div>
        <div>{"Storage Cost " + profit.storageCostUnit}</div>
        <div>{"Shipping Cost " + profit.shippingCostUnit}</div>
        <Table className="inv-table profit-table" bordered condensed hover>
          <thead>
            <tr className="profit-head">
              <th className="inv-head-med">Product</th>
              <th className="inv-head-med">Sales</th>
              <th className="inv-head-med">Cost</th>
              <th className="inv-head-med">Quantity</th>
              <th className="inv-head-med">Precentage</th>
              <th className="inv-head-med">Margin</th>
            </tr>
          </thead>
          <tbody>
            <tr className="summary-row">
              <td className="inv-head-med">Totals</td>
              <td className="inv-head-med">{profit.totalSales}</td>
              <td className="inv-head-med">{profit.totalCost}</td>
              <td className="inv-head-med">{profit.totalUnits}</td>
              <td className="inv-head-med">{"N/A"}</td>
              <td className="inv-head-med">{utilFunctions.round2money(profit.productMargin * 100)}%</td>
            </tr>
            {sortedProfit.map(RenderProduct)}
          </tbody>
        </Table>
      </Panel>

    )
  }
}

