import React from 'react';
import { Card, CardTitle } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';


class ActivityEntry extends React.Component {


  render() {
    const { transaction, i } = this.props;

    return (
      <tr key={i}>
        <td className="tran-head-med">{DateFnsFormat(parseISO(transaction.activityTime), 'dd MMM, H:m:s')}</td>
        <td className="tran-head-med">{transaction.userName}</td>
        <td className="tran-head-med">{transaction.activityMessage}</td>
      </tr>
    )
  }
}

class ActivityHistory extends React.Component {
  constructor(props) {
    super(props)
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);

  }
  componentDidMount() {
    this.props.fetchActivityFromDate(DateFnsFormat(addDays(new Date(), -3), 'yyyy-MM-dd'));

  }

  createDate = (date) => {
    console.log('date: ' + date)
    if (date == 'na') {
      return null;
    } else {
      return new Date(parseISO(date));
    }
  }

  handleFromChange(date) {
    this.props.fetchActivityBetweenDates(DateFnsFormat(date, 'yyyy-MM-dd'), this.props.activity.toDate);

  }

  handleToChange(date) {
    this.props.fetchActivityBetweenDates(this.props.activity.fromDate, DateFnsFormat(date, 'yyyy-MM-dd'))
  }



  render() {

    if (this.props.activity.isFetching) {
      return (
        <Card className="dashboard" header="Activity Transactions">
          <CardTitle>Activity Transactions</CardTitle>
          <div className="loader">Loading...</div>
        </Card>
      )
    }
    else {
      return (
        <Card className="dashboard">
          <CardTitle>Activity Transactions</CardTitle>
          <Row>
            <Col sm="3"><span>from: </span>
              <DatePicker
                selected={this.createDate(this.props.activity.fromDate)}
                onChange={this.handleFromChange}
                dateFormat="dd-MM-yyyy" />
            </Col>
            <Col sm="9"><span>to: </span>
              <DatePicker
                selected={this.createDate(this.props.activity.toDate)}
                onChange={this.handleToChange}
                dateFormat="dd-MM-yyyy"
              />
            </Col>
          </Row>
          <Table className="inv-table" striped bordered hover>
            <thead>
              <tr>
                <th className="inv-head-med">Date</th>
                <th className="tran-head-med">User</th>
                <th className="tran-head-med">Message</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.activity.lists.map((transaction, i) => <ActivityEntry
                  key={i} i={i} transaction={transaction} />)
              }
            </tbody>
          </Table>
        </Card>
      )
    }

  }
}

export default ActivityHistory;
