import React from 'react';
import ReactDOM from 'react-dom';

import { Form } from 'react-bootstrap';

import { Modal } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { FormLabel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';

// SUPPORTS ADDING TO PRICELISTS + STOCKCOUNTS
class AddProduct extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      showModal: false,
       mDescr : '',
       mProdCode: '',
       mPrice: '',
       showSelect: false,
       prodCodeError: '',
       mQuantity: 0,
      endDate: null,
      mBatch:''
    };
  }


  errorCode(){
    let str = '';
    if( this.state.prodCodeError === 'error'){
      str = validationState="error";
    }
    return( str );
  }

  renderDropButton = (product) => {
    const { priceListId } = this.props.params;
  //  const priceListIndex = this.props.pricelists.lists.findIndex((pricelist) => pricelist.name === priceListId);

    const priceList = this.props.editablePricelist;

    const index = priceList.priceListItems.findIndex((plist)=> plist.prodCode === product.prodCode);
    let str = '';
    if( index < 0 ) {
      str =  <DropdownItem key={product.prodCode} value={product.prodCode}>{product.prodCode}</DropdownItem>;
    }
    return (
        str
      )
  }

  renderOption = (product) => {
  //  const { priceListId } = this.props.params;
  //  const priceListIndex = this.props.pricelists.lists.findIndex((pricelist) => pricelist.name === priceListId);

//    const priceList = this.props.pricelists.lists[priceListIndex];
    const priceList = this.props.editablePricelist;
    const index = priceList.priceListItems.findIndex((plist)=> plist.prodCode === product.prodCode);
    let str = '';
    if( index < 0 ) {
      str = <option key={product.prodCode} value={product.prodCode} >{product.prodCode}</option>;
    }
    return (
        str
      )
  }

  handleProdChange = (e) => {
   this.setState({mProdCode: e.target.value});
   const index = this.props.products.items.findIndex((prod)=> prod.prodCode === e.target.value);
   const product = this.props.products.items[index];
   this.setState({mDescr: product.description});
   this.setState({mPrice: product.price});
   this.setState({mAccountCode: product.accountCode});
   this.setState({mTaxCode: product.taxCode});
   this.setState({showSelect: true});
 }

 handlePriceChange = (e) =>{
   this.setState({mPrice: e.target.value});
 }

 handleQuantityChange = (e) =>{
   this.setState({mQuantity: e.target.value});
 }

 handleBatchChange = (e) =>{
  this.setState({mBatch: e.target.value});
}

 close = () => {
    this.setState({ showModal: false });
    this.setState( {mProdCode: ''});
    this.setState( {mDescr: ''});
    this.setState({mPrice: ''});
    this.setState({mBatch: ''});
    this.setState({mTaxCode: ''});
    this.setState({mAccountCode: ''});
    this.setState({showSelect: false});

  }
  open = () =>{
    this.setState({ showModal: true });
  }

  handleOnSubmit= (e) =>{
    e.preventDefault();
    this.setState( {prodCodeError: ''});


    if( this.state.mProdCode === ''){
      this.setState( {prodCodeError: 'error'});
    }else{
      if( this.isPriceList ){
        this.props.addProductToPricelist(this.props.sourceId,this.state.mProdCode, this.state.mPrice, this.state.mAccountCode, this.state.mDescr, this.state.mTaxCode)
      }else{
        // is stockcount
        this.props.addStock(this.props.sourceId,this.state.mProdCode, this.state.mDescr, this.state.mQuantity,this.state.mBatch );

      }
      this.setState({showModal: false});
      this.setState( {mProdCode: ''});
      this.setState( {mDescr: ''});
      this.setState( {mTaxCode: ''});
      this.setState({mAccountCode: ''});
      this.setState({mPrice: 0});
      this.setState({mQuantity: 0});
      this.setState({mBatch:''});

      this.setState({showSelect: true});

    }
  }

  render = () => {
    const {source, sourceId} = this.props;
    console.log('source: ' + source );
    console.log('sourceId: ' + sourceId );

    let showPrice = true;
    let showQuantity = false;
    let isPriceList = false;
    if( source === 'pricelist'){
      showPrice = false;
      showQuantity = true;
      isPriceList = true;
    }
    return (
      <div>
        <Button
          bsSize="small"
          onClick={this.open}
        >Add Product</Button>

          <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header>
              <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Form  onSubmit={this.handleOnSubmit}>
            <Modal.Body>
            <FormGroup controlId="prodCode" onChange={this.handleProdChange}>
               <FormLabel>Product</FormLabel>
             <FormControl as="select" placeholder="select" >
                <option key="select" value="select"  hidden={this.state.showSelect}>{'select'}</option>
                {this.props.products.items.map(this.renderOption)}
             </FormControl>
             <div>{this.state.mDescr}</div>
           </FormGroup>
              <FormGroup   controlId="price" hidden={showPrice} onChange={this.handlePriceChange} >
                <FormLabel>Price</FormLabel>
                <FormControl type="number" step="any" placeholder={this.state.mPrice} />
              </FormGroup>
              <FormGroup   controlId="quantity" hidden={showQuantity} onChange={this.handleQuantityChange} >
                <FormLabel>Quantity</FormLabel>
                <FormControl type="number" placeholder="0"  />
              </FormGroup>
              <FormGroup   controlId="batch" hidden={showQuantity} onChange={this.handleBatchChange} >
                <FormLabel>Batch</FormLabel>
                <FormControl type="text" placeholder={this.state.mBatch}  />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button  onClick={this.close}>Close</Button>
              <Button  type="submit" variant="primary">Add</Button>
            </Modal.Footer>
            </Form>
          </Modal>
          </div>
        )
        }
      }

export default AddProduct;
