import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../actions/actionCreators';
import * as customerActions from '../actions/customerActions';
import * as invoiceActions from '../actions/invoiceActions';
import * as stockcountActions from '../actions/stockcountActions';
import NavigationBar from './NavigationBar';
import LogBar from './LogBar';
import SingleWarehouse from './SingleWarehouse';
import WareHouseGrid from './WareHouseGrid';
import StockCountWareHouse from './StockCountWareHouse';
import Settings from './Settings';
import Dashboard from './Dashboard';
import Transactions from './Transactions';
import Reconcile from './Reconcile';
import ActivityHistory from './ActivityHistory';
import BillTransactionHistory from './BillTransactionHistory';
import {
  Switch,
  Route
} from "react-router-dom";
import CustomerInsights from './CustomerInsights';
import CustomerInsightsDetail from './CustomerInsightsDetail';
import CustomerProductBreakdown from './CustomerProductBreakdown';


class App  extends React.Component { 
  constructor(props) {
    super(props)
  }
render() {

  if (this.props.auth.isAuthenticated) {
    console.log('match params: ' + JSON.stringify(this.props.match.params))

    return (
      <div>
      <div className="header">
        <div className="NavBar" >
        <NavigationBar />
        </div>
      </div>
        <Switch>
          <Route exact path="/" ><Dashboard {...this.props} /></Route>
          <Route  path="/stock" > <WareHouseGrid {...this.props}/></Route>
          <Route path="/dashboard" ><Dashboard {...this.props} /></Route>
          <Route path="/customerinsights" ><CustomerInsights {...this.props} /></Route>
          <Route path="/activity"><ActivityHistory {...this.props}/></Route>
          <Route path="/billtransaction"><BillTransactionHistory {...this.props}/></Route>
          <Route path="/customer/:customerId" ><CustomerInsightsDetail {...this.props} /></Route>
          <Route path="/productbreakdown/:customerId" ><CustomerProductBreakdown {...this.props} /></Route>
      <Route path="/customerBlob/:customerId" render={(props) => <CustomerInsightsDetail  customerId={this.props.match.params.warehouseId} {...this.props} /> }></Route>

          <Route path="/editwh/:warehouseId" render={(props) => <StockCountWareHouse  warehouseId={this.props.match.params.warehouseId} {...this.props} /> }></Route>
          <Route path="/view/:warehouseId/reconcile"><Reconcile {...this.props} /></Route>
          <Route path="/view/:warehouseId/tran"><Transactions {...this.props}/></Route>
          <Route  path="/view/:warehouseId"><SingleWarehouse {...this.props}/></Route>

             <Route path="/settings"><Settings {...this.props}/></Route>
        </Switch>
      </div>
    )
  }else{
    return (
      <div className="maincontainer">
      <div className="header">
        <div className="NavBar" >
        <LogBar requestLogin={this.props.requestLogin} isAuthenticated={this.props.auth.isAuthenticated} errorMessage={this.props.auth.errorMessage} />
      </div>
      </div>
      </div>
    )
  }
}
}



function mapStateToProps(state) {
  return {
    warehouses: state.warehouses,
    products: state.products,
    pricelists: state.pricelists,
    invoices: state.invoices,
    editablePricelist: state.editablePricelist,
    editableInvoice: state.editableInvoice,
    auth: state.auth,
    customers: state.customers,
    stockcount: state.stockcount,
    customersalesdata: state.customersalesdata,
    warehousedashboard: state.warehousedashboard,
    transactions: state.transactions,
    statements: state.statements,
    reconcilliation: state.reconcilliation,
    activity: state.activity,
    billtransaction: state.billtransaction

  }
}
function mapDispachToProps(dispatch) {
  return bindActionCreators({...actionCreators,...customerActions, ...invoiceActions, ...stockcountActions}, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(App)