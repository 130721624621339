import React from 'react';
import { Card , CardTitle} from 'react-bootstrap';
import SalesCustomer from './SalesCustomer';
import ProfitFunction from './ProfitFunction';


class CustomerInsightsDetail extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Card className="CustomerInsights"   header="Customer Insights">
        <CardTitle>Customer Insights</CardTitle>
        <div><SalesCustomer contactName={this.props.match.params.warehouseId} /></div>
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='3' /></div>
        <div><a href={'/productbreakdown/' + this.props.match.params.warehouseId}>See YoY Product Breakdown</a></div>
      </Card>
    )
  }

}

export default CustomerInsightsDetail;
