import React from "react";

import { useFetch } from '../services/useFetch';
import { Card, CardTitle} from 'react-bootstrap';
import { serverURL } from "../services/api";
import { getGraphSeries, mergeGraphSeries} from "../services/util"; 
import { AgCharts } from 'ag-charts-react';



export default function HandlingVsUnitsShippedBrakesMoM(id) {
  const [shipunits1] = useFetch(serverURL + "bill/brakesvsunits/mom/2022-01-01/2022-12-31");
  const [shipunits2] = useFetch(serverURL + "bill/brakesvsunits/mom/2023-01-01/2023-12-31");
  const [shipunits3] = useFetch(serverURL + "bill/brakesvsunits/mom/2024-01-01/2024-12-31");


  if( !shipunits1 || !shipunits2 || !shipunits3){
    return (
      <Card className="foulger">
        <CardTitle>{"Cost pr Unit From Brakes"}</CardTitle>
       <div className="loader">Loading...</div>
      </Card>
    )
  }
  else{

 
    let costSeries = getGraphSeries(shipunits1.monthList);
    costSeries = mergeGraphSeries(costSeries, shipunits2.monthList, 1);
    costSeries = mergeGraphSeries(costSeries, shipunits3.monthList, 2);

    const options = {
      data: costSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2023",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y1",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2025",
          yKey: "y2",
        }

      ],
      title: { text: "Cost pr Unit From Brakes" }
    };

    return (
      <Card className="foulger" >
        <div><AgCharts options={options} /></div>
    <div>Handling Cost per case 2023: {shipunits1.totalRatio} </div>
    <div>Handling and shipping Cost per case 2024: {shipunits2.totalRatio} </div>
    <div>Handling and shipping Cost per case 2025: {shipunits3.totalRatio} </div>

    </Card>

  );
}
}