import React, { useState, useEffect } from "react";

import { useFetch } from '../services/useFetch';
import { Card, CardTitle } from 'react-bootstrap';
import { serverURL } from "../services/api";
import { getGraphSeries, mergeGraphSeries } from "../services/util";
import { AgCharts } from 'ag-charts-react';


export default function ShippingVsSalesMoM(id) {
  const [shipunits1] = useFetch(serverURL + "bill/transportvssales/mom/FDC/2023-01-01/2023-12-31");
  const [shipunits2] = useFetch(serverURL + "bill/transportvssales/mom/FDC/2024-01-01/2024-12-31");
  const [shipunits3] = useFetch(serverURL + "bill/transportvssales/mom/FDC/2025-01-01/2025-12-31");

  if (!shipunits1 || !shipunits2 || !shipunits3) {
    return (
      <Card className="foulger">
        <CardTitle>{"Transport Cost vs Sales Value from  FDC"}</CardTitle>
        <div className="loader">Loading...</div>
      </Card>
    )
  }
  else {

    let costSeries = getGraphSeries(shipunits1.monthList);
    costSeries = mergeGraphSeries(costSeries, shipunits2.monthList, 1);
    costSeries = mergeGraphSeries(costSeries, shipunits3.monthList, 2);

    const options = {
      data: costSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2023",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y1",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2025",
          yKey: "y2",
        }

      ],
      title: { text: "Transport Cost vs Sales Value from  FDC" }
    }

    return (
      <Card className="foulger" header={shipunits3.name}>
        <div><AgCharts options={options} /></div>
        <div>Shipping cost as % of sales 2023: {shipunits1.totalRatio} </div>
        <div>Shipping cost as % of sales 2024: {shipunits2.totalRatio} </div>
        <div>Shipping cost as % of sales 2025: {shipunits3.totalRatio} </div>
      </Card>

    );
  }
}
