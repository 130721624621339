import React from 'react';
import { createRoot } from 'react-dom/client';
//import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store, { history } from './store'
// Import css
import css from './styles/style.styl';

// Import Components
import App from './components/App';




// import react router deps
import { BrowserRouter, Route } from 'react-router-dom'



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Route path="/:filter?/:warehouseId?" component={App}>
            </Route>
        </BrowserRouter>
    </Provider>
);

