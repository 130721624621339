import { 
    getSessionHeader
    } from './api';
export const fetchGraphData = (url) => {

  return (  fetch(url, {
        method: "GET",
            headers: new Headers({
            'Authorization' : getSessionHeader(),
                'Content-Type': 'application/json',
            'accept': 'application/json'
            })
    })
  )
};

export default fetchGraphData;
