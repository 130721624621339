import React, { Component } from 'react'
import { Container, Form , Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { FormLabel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


class Login extends React.Component {
  render() {
    const { errorMessage } = this.props;

    return (
<Container>
<Form>
      <Form.Group as={Row} className="mb-3" controlId="user">
        <Form.Label column sm="4" className="rightAlign" >
          Name
        </Form.Label>
        <Col sm="4">
          <Form.Control   placeholder="user name" />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="password">
      <Form.Label column sm="4" className="rightAlign" >
          Password
        </Form.Label>
        <Col sm="4">
          <Form.Control type="password" placeholder="Password" />
        </Col>
      </Form.Group>
      <Row>
        <Col sm="4">
        </Col>
        <Col sm="4">
        <Button onClick={(event) => this.handleClick(event)} className="btn btn-primary">
          Login
        </Button>
        {errorMessage &&
          <p>{errorMessage}</p>
        }
        </Col>

      </Row>
  
        </Form>
        </Container>
    )
  }

  handleClick(event){
    const user =  document.getElementById("user").value.trim();
    const password =   document.getElementById("password").value.trim();
    console.log( 'user: ' +  user + ' pw: ' + password);
    console.log(this.props.requestLogin);
    this.props.requestLogin(user, password);

  };
}

Login.propTypes = {
  errorMessage: PropTypes.string
}

export default Login;
