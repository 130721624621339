import React from 'react';
import { Card } from 'react-bootstrap';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap';
var moment = require('moment');

class InvoiceComponent extends React.Component {

  render() {
    const {invoice, i} =this.props;
    return (
        <tr><td><Link  to={`/invoice/${invoice.invoiceNumber}/inv`}>{invoice.invoiceNumber}</Link></td>
        <td>{invoice.bolContact.name}</td>
        <td>{moment(invoice.dateTimeOfSubmission, moment.ISO_8601).format('DD-MM-YYYY')}</td>
        <td>{invoice.totalSum}</td></tr>
    );
  }
}

class InvoiceList extends React.Component {

  render() {
    const { invoiceList, name } = this.props;
    if (invoiceList == null || invoiceList == []) {
      return("")
    }else{
      return (

        <Card>
          <Card.Title>{name}</Card.Title>

          <Table className="inv-table" striped bordered  hover>
            <thead>
              <tr>
              <th className="tran-head-med">Invoice</th>
              <th className="tran-head-med">Customer</th>
              <th className="tran-head-med">Date</th>
              <th className="tran-head-med">Value</th>
              </tr>
            </thead>
            <tbody>

          {
          invoiceList.map((invoice, i) => <InvoiceComponent
                                                      key={i} i={i} invoice={invoice} />)
          }
        </tbody>
      </Table>
      </Card>
      )
    }
  }
}
export default InvoiceList;
