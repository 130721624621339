import React from 'react';
import { Table } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
var moment = require('moment');

function RenderStock(product, i) {

  return (<tr key={i}>
    <td className="fixed-small">{product.prodCode}</td>
    <td>{product.descr}</td>
    <td className="fixed-small">{product.quantity}</td>
  </tr>)
};

export default function WareHouseSummary(props) {

  let url = "inventory/warehousesummary/" + props.warehouse; 
  
  console.log('detailed url : ' + url )
  const [stock] = useFetch(serverURL + url ); 

  if (!stock) {
    return (
      <Card className="foulger"   header={props.title}>
        <div className="loader">Loading...</div>
      </Card>
    )
  }
  else {
    let sortedStock = stock.productsSummary.sort((p1, p2) => (p1.prodCode > p2.prodCode) ? 1 : (p1.prodCode < p2.prodCode) ? -1 : 0);

    return (
      <Card header={"Stock in " + stock.wareHouseName}>
        <Card.Title>{"Stock in " + stock.wareHouseName + " @" + moment(stock.stockTakeDate, moment.ISO_8601).format('DD-MM-YYYY') }</Card.Title>

        <Table striped hover>
            <thead>
            <tr className="profit-head">
            <th className="fixed-small">Product</th>
            <th>Description</th>
            <th className="fixed-small">Total</th>
            </tr>
          </thead>
          <tbody>
            {sortedStock.map(RenderStock)}
          </tbody>
        </Table>
      </Card>

    )
  }
}

