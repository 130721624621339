import React from 'react';
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { ButtonToolbar} from 'react-bootstrap';
import { Button} from 'react-bootstrap';
import { Panel} from 'react-bootstrap';
import AddProduct from './AddProduct';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

class StockCountWareHouse extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      endDate:new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    console.log(JSON.stringify('stockcount: ' + this.props))


  }

  handleChange(date) {
    this.setState({
      endDate: date
    });
  }


  componentDidMount() {
    if( ! this.props.stockcount.isSynching && this.props.stockcount.warehouse === '' &&  this.props.warehouses.units.length > 0 ){
      const { warehouseId } = this.props.match.params.warehouseId;
     // this.props.getStockById(warehouseId, this.props.warehouses);
     this.props.fetchStockCount(warehouseId); 
    }
    if( this.props.products.items.length === 0 ){
      this.props.fetchProducts();
    }
  }

  renderNoProduct = (units) => {
    if( units.length === 0){
      return (
          <tr key="nop"><td colSpan="3">No products in this warehouse</td></tr>
      )
    }else{
      return ''
    }

  };

  renderProduct = (product, i) => {
    return (<tr key={i}>
          <td className="fixed-small">{product.prodCode}</td>
          <td>{product.descr}</td>
          <td className="fixed-small">{product.batch}</td>
          <td className="fixed-small">{product.expiryDate}</td>
          <td className="fixed-small"><Form inline>
            <FormGroup controlId="warehouseQuantity">
              <FormControl type="number" placeholder={product.quantity} onChange={this.handleClick.bind(this, product.prodCode, product.batch)}/>
            </FormGroup></Form></td></tr>
          )
  };

  handleClick = (prodCode,batch,  e) => {
      const productIndex = this.props.stockcount.stockItems.findIndex((stockItem) => stockItem.prodCode === prodCode  && stockItem.batch === batch);

      console.log('prod index num: ' + productIndex );
      console.log('handleclick batch: ' +  batch);

      console.log('handleclick Q: ' +  e.target.value);
      this.props.updateStockQuantity(this.props.warehouse, prodCode, productIndex,e.target.value, );

  };

  handleStockcountSubmit = () => {
      console.log('handleStockcountSubmit tbd');
      this.props.submitStockCount(DateFnsFormat(this.state.endDate,'yyyy-MM-dd'), this.props.stockcount.stockItems, this.props.stockcount.warehouse)
      //this.props.submitInvoice(this.props.e);
  };

  render() {
    console.log('stockcount R: ' + JSON.stringify(this.props))
    console.log('stockcount match: ' + JSON.stringify(this.props.match.params))
    console.log(this.props.warehouseId)
    const { warehouseId } = this.props.warehouseId;
    const stockCount = this.props.stockcount;

     if( this.props.stockcount.isSynching === true){
       return(
         <Panel className="warehouse" header={'Stock Count for ' + warehouseId}>
         spinner .......
         </Panel>
    )
     }else{

    return (
      <Panel className="warehouse" header={'Stock Count for ' + stockCount.warehouse}>

          <Table striped hover>
            <thead>
              <tr>
          <th className="fixed-small">Product</th>
          <th>Description</th>
          <th className="fixed-small">Batch</th>
          <th className="fixed-small">Expiry</th>
          <th className="fixed-small">Quantity</th>
        </tr>
      </thead>
      <tbody>
          {
              stockCount.stockItems.map(this.renderProduct)
          }
          {this.renderNoProduct(stockCount.stockItems)}
        </tbody>
      </Table>
      Stock count date <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleChange}
                          dateFormat="dd-MM-yyyy"
                        />
      <ButtonToolbar className="button-bar">
            <Link  to={'/stock'}>
              <Button bsSize="small" bsStyle="primary"
              onClick={this.handleStockcountSubmit}
              >Apply</Button>
              </Link>
              <AddProduct {...this.props} sourceId={warehouseId} source="stockcount"/>

        </ButtonToolbar>
        </Panel>
    )
  }
  }
}

export default StockCountWareHouse;
