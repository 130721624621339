import React from 'react';
import { Nav, Navbar, NavLink , Container} from 'react-bootstrap';

class NavigationBar extends React.Component {
  state = {
    activeHref: ''
  };

  componentDidMount() {
    this.setState.activeHref
  }

  handleSelect = (key) => {
      location.href = key;
  };

  render() {
    return (
      <Navbar expand="lg" className="bg-body-tertiary">
      <Nav variant="tabs"  onSelect={this.handleSelect}>
      <NavLink eventKey={"/"} href="/">Dashboard</NavLink>
        <NavLink eventKey={"/customerinsights"} href="/customerinsights">Customer Insights</NavLink>
        <NavLink eventKey={"/billtransaction"} href="/billtransaction">Cost Insights</NavLink>
        <NavLink eventKey={"/view/FDC"} href="/view/FDC">FDC</NavLink>
        <NavLink eventKey={"/view/Yearsleys"} href="/view/Yearsleys">Yearsley</NavLink>
        <NavLink eventKey={"/view/Garage"} href="/view/Garage">Garage</NavLink>
        <NavLink eventKey={"/view/Maynard"} href="/view/Manard">Maynard</NavLink>
        <NavLink eventKey={"/view/Lynda"} href="/view/Lynda">Lynda</NavLink>
        <NavLink eventKey={"/activity"} href="/activity">Activity</NavLink>
        <NavLink eventKey={"/settings"} href="/settings">Settings</NavLink>

      </Nav>

      </Navbar>
    );
  }
}

export default NavigationBar;
