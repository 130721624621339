import React from 'react';
import TransactionHistory from './TransactionHistory';
import { Card, CardTitle} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import addDays from 'date-fns/addDays';


class Transactions extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      selectDate: addDays(new Date(), -90)
    };
    this.handleToChange = this.handleToChange.bind(this);
  }

  handleToChange(date) {
    this.setState({
      selectDate: date
    });
  }

  doit = (warehouse) => {
    this.props.reconcileWarehouseUpto(warehouse, DateFnsFormat(this.state.selectDate,'yyyy-MM-dd'));
  };

  componentDidMount = () => {
    const  warehouseId  = this.props.match.params.warehouseId;

    this.props.fetchWarehouseTransactionsFromDate(warehouseId, DateFnsFormat(addDays(new Date(), -90), 'yyyy-MM-dd'), 'all');

  }

  render() {
    const  warehouseId  = this.props.match.params.warehouseId;
    if( this.props.transactions.isFetching ){
      return(
      <Card header={warehouseId + ' Transactions'}>
         <div className="loader">Loading...</div>
     </Card>
    )

    }else{
    return (
        <Card>
          <CardTitle>{warehouseId}</CardTitle>
          <TransactionHistory hidden={false} transactions={this.props.transactions}
            deleteTransaction={this.props.deleteTransaction}
            reconcileSingle={this.props.reconcileSingle}
            unreconcileTransaction={this.props.unreconcile}
            id={warehouseId}
            fetchTransactionsBetweenDates={this.props.fetchWarehouseTransactionsBetweenDates} />

            <div><h4>Reconcile all transactions upto date</h4>
            Select from
            <DatePicker
                              selected={this.state.selectDate}
                              onChange={this.handleToChange}
                              dateFormat="dd-MM-yyyy"
                              />
          <button onClick={() => this.doit(warehouseId)} className="btn btn-primary">
            Do It
          </button>
            </div>

        </Card>
    )
  }
}
}

export default Transactions;
