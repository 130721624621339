import React from 'react';
import Dropzone from 'react-dropzone'
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import { uploadInvoiceCSV
} from '../services/api'

class BrakesInvoiceUploader extends React.Component {
 

  constructor (props) {
    super(props)
    this.state = {
      files: [],
      name: ''
    };
  }

  onDrop = (acceptedFiles) => {
    this.setState({
      files: acceptedFiles
    });
    this.setState({
      name: acceptedFiles[0].name
    });
    let date = uploadInvoiceCSV('Brakes', acceptedFiles[0]);
    date.then(function(value) {
        console.log('promise log: ' + value);
  // expected output: "foo"
  });
    console.log('last statement date' + JSON.stringify(date));
  };

  onOpenClick = () => {
    this.dropzone.open();
  };

  render() {
      return (
          <div>
              <Dropzone ref={(node) => { this.dropzone = node; }} multiple={false} accept={'text/csv'} onDrop={this.onDrop}>
                  <div>Drag Brakes Invoice csv statement here, or click to select files to upload.</div>
              </Dropzone>
              {this.state.files.length > 0 ? <div>
              <div>{this.state.files.map((file) => <p key={file.name}>uploaded {file.name} </p> )}</div>
              </div> : null}
          </div>
      );
  }
}

export default BrakesInvoiceUploader;
