import React from 'react';
import { Card, CardTitle } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
import { getGraphSeries } from "../services/util";
import { mergeGraphSeries } from "../services/util";
import { AgCharts } from 'ag-charts-react';
import { loadData } from './SalesCustomer';



export default function TotalSales(props) {
  const id = props.id;
  const [data3] = useFetch(serverURL + "bill/warehousesales/" + id + "/2021-01-01/2021-12-31");
  const [data4] = useFetch(serverURL + "bill/warehousesales/" + id + "/2022-01-01/2022-12-31");
  const [data5] = useFetch(serverURL + "bill/warehousesales/" + id + "/2023-01-01/2023-12-31");
  const [data6] = useFetch(serverURL + "bill/warehousesales/" + id + "/2024-01-01/2024-12-31");
  const [data7] = useFetch(serverURL + "bill/warehousesales/" + id + "/2025-01-01/2025-12-31");

  if (!data4 || !data3 || !data7 || !data5 || !data6) {
    return (
      <Card className="foulger">
        <CardTitle>{'Total Sales ' + props.title}</CardTitle>
        <div className="loader">Loading...</div>
      </Card>
    )
  }
  else {

    let costSeries = getGraphSeries(data3.monthList);
    costSeries = mergeGraphSeries(costSeries, data4.monthList, 1);
    costSeries = mergeGraphSeries(costSeries, data5.monthList, 2);
    costSeries = mergeGraphSeries(costSeries, data6.monthList, 3);
    costSeries = mergeGraphSeries(costSeries, data7.monthList, 4);

    const dataArray = [
      data3, data4, data5, data6, data7
    ];




    const options = {
      data: costSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2021",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2022",
          yKey: "y1",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2023",
          yKey: "y2",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y3",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2025",
          yKey: "y4",
        }
      ],
      title: { text: 'Total Sales ' + props.title },

    };



    return (
      <Card className="foulger">
        <div><AgCharts options={options} /></div>
        {loadData(props.title, dataArray)}
      </Card>
    )
  }
}
