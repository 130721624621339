import React from 'react';

import { Form } from 'react-bootstrap';

import { Modal } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { FormLabel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as utilFunctions from '../services/util';
import parseISO from 'date-fns/parseISO';
import DateFnsFormat from 'date-fns/format';





class StockAdjustmentWindow extends React.Component {

      constructor (props) {
           super(props)
           this.state = {
             showModal: false,
             mQuantity: utilFunctions.isNumeric(props.transferQuantity) ? (props.type=='GI'? props.transferQuantity: ('-'+props.transferQuantity)): 0,
             prodCodeError: '',
             reason: this.props.reference,
             prodCodeError: '',
             adjDate: utilFunctions.isWhitespaceEmptyString(props.transferDate) ? null : parseISO(props.transferDate)
           };
           this.handleChange = this.handleChange.bind(this);
       }

  handleChange(date) {
    this.setState({
      adjDate: date
    });
  }

  componentDidMount() {
    if(  ! this.props.stockcount.isSynching && ! this.props.warehouses.isFetching && this.props.warehouses.units.length === 0 ){
      this.props.fetchWarehouses(warehouseId);
    }
    const { transferQuantity, transferDate, isReconcile, reference} =  this.props;
    if ( utilFunctions.isNumeric(transferQuantity) ){
      this.setState({mQuantity: parseInt(transferQuantity)});
    }
    if( ! utilFunctions.isWhitespaceEmptyString(transferDate)) {
      this.setState({selectValue: transferDate});
    }
  }

  handleQuantityChange = (e) => {
    this.setState({mQuantity: e.target.value});
  };

  handleReasonChange = (e) => {
    this.setState({reason: e.target.value});
  };

  handleBatchChange = (e) => {
    this.setState({batch: e.target.value});
  };

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const  warehouseId  = this.props.match.params.warehouseId;
    const wareHouseIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === warehouseId);
    const wareHouseReceiveIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === this.state.receiveWareHouse);
  this.setState( {prodCodeError: ''});

    console.log('prod' + e);
    if( this.state.mProdCode === '' ){
      this.setState( {prodCodeError: 'error'});
    }else{
      this.props.adjustWarehouse(warehouseId,this.props.adjustmentProduct,
        this.state.mQuantity,this.state.reason, 
        DateFnsFormat( this.state.adjDate, 'yyyy-MM-dd'), this.props.batch);
    //  this.setState({showModal: false});
      this.setState({mQuantity: 0});
      if( this.props.isReconcile === 'true'){
        this.props.fetchReconcilliationItem(warehouseId, this.props.reconcilliation.currentPage);
        this.close();
      }else{
        this.props.viewWarehouses()
      }

    }
  };

  renderLink = (button) => {
    if( button ){
        return (
          <Button
            bsSize="xsmall"
            onClick={this.open}
          >Adjust</Button>
        )

    }else {
      return ( <div onClick={this.open}>Adjust</div>)
    }
  };

  render() {
    const { warehouseId } = this.props;
    if ( this.props.warehouses.units.length === 0  || this.props.warehouses.isFetching){
        return (
          <div>
          <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
            <Modal.Header>
              <Modal.Title>Adjust Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="loader">Loading...</div>
            </Modal.Body>
             <Modal.Footer>
               </Modal.Footer>
           </Modal>
           </div>
        )
    } else{
      const wareHouseIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouseName === warehouseId);
      const wh = this.props.warehouses.units[wareHouseIndex];
      const { button}  = this.props;

      return (
        <div>
        { this.renderLink(button)}
            <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
              <Modal.Header>
                <Modal.Title>Adjust Product</Modal.Title>
              </Modal.Header>
              <Form  onSubmit={this.handleOnSubmit}>
              <Modal.Body>
                <div className="inv-top inv-date"><p>Product:</p>
                   <p>{this.props.adjustmentProduct}</p>

              </div>
              <div className="inv-top inv-date"><p>Batch:</p>
                   <p>{this.props.batch}</p>

              </div>
               <div className="inv-top inv-date"><p>Date:</p>

               <DatePicker 
               selected={this.state.adjDate} 
               onChange={this.handleChange}
               dateFormat="dd-MM-yyyy"
               />

             </div>
                 <div className="inv-top to-customer">
                   <p>quantity:</p>
                   <FormGroup  controlId="quantity" >
                     <FormControl bsSize="small" type="number" value={this.state.mQuantity} onChange={this.handleQuantityChange}  />
                  </FormGroup>
               </div>
               <div className="inv-top to-customer">
                 <p>Reference:</p>
                <FormGroup controlId="reason">
                    <FormControl
                      type="text"
                      placeholder={this.state.reason}
                      onChange={this.handleReasonChange}
                    />
                </FormGroup>
            </div>
              </Modal.Body>

              <Modal.Footer>
                <Button  onClick={this.close}>Cancel</Button>
                <Button  onClick={this.handleOnSubmit} variant="primary">Apply</Button>
              </Modal.Footer>
              </Form>


            </Modal>
            </div>
          )
          }
        }
}

export default StockAdjustmentWindow;
