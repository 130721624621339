import React, { useState, useEffect } from "react";
import { fetchGraphData } from '../services/fetchGraphData';
import { Card, CardTitle, Container, Row, Col } from 'react-bootstrap';

import CsvDownloadButton from 'react-json-to-csv'

import { serverURL } from "../services/api";
import { getGraphSeries, round2money } from "../services/util";
import { mergeGraphSeries } from "../services/util";
import { AgCharts } from 'ag-charts-react';

function getChange(lastValue, newValue ){
  if ( lastValue == 0 ) { 
    return 'N/A'; 
  }
  else if( newValue == 0 ){ 
    return '-100 %'; 
  }else{
    const change =  ((newValue - lastValue)/lastValue)*100; 
    return round2money(change) + ' %'; 
  }
}

export function loadData(contact, data) {
  return (<Container>
    <Row>
      <Col><span><b>Yearly Sales</b></span></Col>
      <Col><span><b>Change YoY</b></span></Col>
      <Col><span><b>Export</b></span></Col>
    </Row>
    <Row>
      <Col>
      Sales 2021: {data[0].totalRatio}</Col>
      <Col>{getChange(0, data[0].totalRatio)}</Col>
      <Col>
      <CsvDownloadButton delimiter=","
      filename={contact + '2021'}
      data={data[0].monthList}
      style={{
        borderRadius: "3px",
        display: "inline-block",
        cursor: "pointer", "color": "#ffffff",
        fontSize: "11px",
        fontWeight: "bold",
        padding: "3px 12px",
        textDecoration: "none",
        textShadow: "0px 1px 0px #9b14b3",
        margin: "10px 0px 0px 50px",
        color: "black"
      }}
    >Export 2121 Data</CsvDownloadButton></Col>
   </Row><Row><Col>Sales 2022: {data[1].totalRatio}</Col>
      <Col>{getChange(data[0].totalRatio, data[1].totalRatio)}</Col>
      <Col>
   <CsvDownloadButton delimiter=","
      filename={contact + '2022'}
      data={data[1].monthList}
      style={{
        borderRadius: "3px",
        display: "inline-block",
        cursor: "pointer", "color": "#ffffff",
        fontSize: "11px",
        fontWeight: "bold",
        padding: "3px 12px",
        textDecoration: "none",
        textShadow: "0px 1px 0px #9b14b3",
        margin: "10px 0px 0px 50px",
        color: "black"
      }}
    >Export 2022 Data</CsvDownloadButton></Col>
    </Row><Row><Col>Sales 2023: {data[2].totalRatio}</Col>
      <Col>{getChange(data[1].totalRatio, data[2].totalRatio)}</Col>
      <Col>
    <CsvDownloadButton delimiter=","
      filename={contact + '2023'}
      data={data[2].monthList}
      style={{
        borderRadius: "3px",
        display: "inline-block",
        cursor: "pointer", "color": "#ffffff",
        fontSize: "11px",
        fontWeight: "bold",
        padding: "3px 12px",
        textDecoration: "none",
        textShadow: "0px 1px 0px #9b14b3",
        margin: "10px 0px 0px 50px",
        color: "black"
      }}
    >Export 2023 Data</CsvDownloadButton></Col>
    </Row><Row>
      <Col>Sales 2024: {data[3].totalRatio}</Col>
      <Col>{getChange(data[2].totalRatio, data[3].totalRatio)}</Col>
      <Col>
      <CsvDownloadButton delimiter=","
      filename={contact + '2024'}
      data={data[3].monthList}
      style={{
        borderRadius: "3px",
        display: "inline-block",
        cursor: "pointer", "color": "#ffffff",
        fontSize: "11px",
        fontWeight: "bold",
        padding: "3px 12px",
        textDecoration: "none",
        textShadow: "0px 1px 0px #9b14b3",
        margin: "10px 0px 0px 50px",
        color: "black"
      }}
    >Export 2024 Data</CsvDownloadButton></Col>
    </Row><Row><Col>Sales 2025: {data[4].totalRatio}</Col>
      <Col>{getChange(data[3].totalRatio, data[4].totalRatio)}</Col>
      <Col>
    <CsvDownloadButton delimiter=","
      filename={contact + '2020'}
      data={data[4].monthList}
      style={{
        borderRadius: "3px",
        display: "inline-block",
        cursor: "pointer", "color": "#ffffff",
        fontSize: "11px",
        fontWeight: "bold",
        padding: "3px 12px",
        textDecoration: "none",
        textShadow: "0px 1px 0px #9b14b3",
        margin: "10px 0px 0px 50px",
        color: "black"
      }}
    >Export 2025 Data</CsvDownloadButton></Col>
    </Row>
  </Container>
  )
}



export default function SalesCustomer(props) {
  const contact = props.contactName;
  const url1 = serverURL + "bill/sales/" + contact + "/2021-01-01/2021-12-31";
  const url2 = serverURL + "bill/sales/" + contact + "/2022-01-01/2022-12-31";
  const url3 = serverURL + "bill/sales/" + contact + "/2023-01-01/2023-12-31";
  const url4 = serverURL + "bill/sales/" + contact + "/2024-01-01/2024-12-31";
  const url5 = serverURL + "bill/sales/" + contact + "/2025-01-01/2025-12-31";

  const urls = [url1,
    url2,
    url3,
    url4,
    url5,
  ]
  const [outputSeries, setOutputSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);


  let costSeries = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all(
          urls.map(async (url, index) => {
            const response = await fetchGraphData(url);
            if (!response.ok) {
              throw new Error(`Failed to fetch ${url}`);
            }
            const data = await response.json();
            console.debug(`Response from source ${index + 1}:`, data); // Log each API response
            return data;
          })
        );
        console.debug("all responses:" + responses);

        costSeries = getGraphSeries(responses[0].monthList);
        costSeries = mergeGraphSeries(costSeries, responses[1].monthList, 1);
        costSeries = mergeGraphSeries(costSeries, responses[2].monthList, 2);
        costSeries = mergeGraphSeries(costSeries, responses[3].monthList, 3);
        costSeries = mergeGraphSeries(costSeries, responses[4].monthList, 4);
        console.log("cost series: " + JSON.stringify(costSeries));

        setOutputSeries(costSeries);
        setData(responses); 
      } catch (err) {
        setError(err.message);
      } finally {
        console.debug("completed all fetches and set loading false")
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return (
      <Card className="foulger">
        <CardTitle>{'Sales ' + contact}</CardTitle>
        <div className="loader">Loading...</div>
      </Card>
    )
  }
  else {


    console.log(" cost series late " + outputSeries)

    let options = {
      data: outputSeries,
      series: [
        {
          type: "bar",
          xKey: "x",
          yKey: "y",
          yName: "2021",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2022",
          yKey: "y1",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2023",
          yKey: "y2",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2024",
          yKey: "y3",
        },
        {
          type: "bar",
          xKey: "x",
          yName: "2025",
          yKey: "y4",
        }
      ],
      title: { text: 'Sales ' + contact }
   };

    return (
      <div>
      <Card className="foulger" >
        <div><AgCharts options={options} /></div>
        {loadData(contact, data)}
      </Card>
      </div>

    );
  }
}
