import React from 'react';

import { Form } from 'react-bootstrap';

import { Modal } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { FormLabel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import parseISO from 'date-fns/parseISO';
import * as utilFunctions from '../services/util';
import DateFnsFormat from 'date-fns/format';




class TransferWindow extends React.Component {
          constructor (props) {
            super(props)
            this.state = {
              showModal: false,
              receiveWareHouse: '',
              mQuantity: utilFunctions.isNumeric(props.transferQuantity) ? props.transferQuantity: 0,
              prodCodeError: '',
              endDate: utilFunctions.isWhitespaceEmptyString(props.transferDate) ? null : parseISO(props.transferDate)
            };
            this.handleChange = this.handleChange.bind(this);
        }

          handleChange(date) {
            this.setState({
              endDate: date
            });
          }

  componentDidMount() {
    if( this.props.warehouses.units.length === 0 && ! this.props.warehouses.isFetching){
      this.props.fetchWarehouses(this.props.warehouseId);
    }
  }

  createDate(date){
    console.log('date: ' + date  )
     if( date == 'na'){
       return null;
    }else{
      return new Date(parseISO(date));
    }
  }

  renderOption = (wh) => {
    const index = this.props.warehouse;
    const warehouseId = this.props.warehouseId;

    let str = '';
    if( wh.wareHouseName === warehouseId){
      str = '';
    }else{
      str = <option key={wh.wareHouseName} value={wh.wareHouseName}>{wh.wareHouseName}</option>;
    }
    return (
        str
      )
  };

  handleWareHouseChange = (e) => {
   this.setState({receiveWareHouse: e.target.value});
 };

  handleQuantityChange = (e) => {
    this.setState({mQuantity: e.target.value});
  };

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const  warehouseId  = this.props.warehouseId;
    const wareHouseIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === warehouseId);
    const wareHouseReceiveIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === this.state.receiveWareHouse);
  this.setState( {prodCodeError: ''});

    console.log('prod' + e);
    if( this.state.mProdCode === '' ){
      this.setState( {prodCodeError: 'error'});
    }else{
      if( this.props.isReconcile === 'true'){
        console.log( warehouseId+ '' + wareHouseIndex + ' ' + this.props.transferProduct + '' +  this.state.mQuantity + " R: " +  this.state.receiveWareHouse + "  " + wareHouseReceiveIndex + " "  + DateFnsFormat(this.state.endDate,'yyyy-MM-dd') + " " + this.props.reference + " " + this.props.reconcilliation.currentPage); 
        this.props.transferWarehouseReconcile(warehouseId,wareHouseIndex,this.props.transferProduct, this.state.mQuantity, this.state.receiveWareHouse, wareHouseReceiveIndex, DateFnsFormat(this.state.endDate,'yyyy-MM-dd'), this.props.reference, this.props.reconcilliation.currentPage, null, this.props.batch)

      }else{
        this.props.transferWarehouse(warehouseId,wareHouseIndex,this.props.transferProduct, this.state.mQuantity, this.state.receiveWareHouse, wareHouseReceiveIndex, DateFnsFormat(this.state.endDate,'yyyy-MM-dd'), this.props.reference, this.props.batch)
      }
    //  this.setState({showModal: false});
      this.setState( {receiveWareHouse: ''});
      this.setState({mQuantity: 0});
      if( this.props.isReconcile === 'true'){
        this.close();
      }else{
        this.props.viewWarehouses()
      }
    }
  };

  renderLink = (button) => {
    if( button ){
        return (
          <Button
            bsSize="xsmall"
            onClick={this.open}
          >Transfer</Button>
        )

    }else {
      return ( <div onClick={this.open}>transfer</div>)
    }
  };

  render() {
    const { warehouseId } = this.props;

    if ( this.props.warehouses.units.length === 0  || this.props.warehouses.isFetching){
        return (
          <div>
          <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header>
              <Modal.Title>Transfer {this.props.transferProduct} from {warehouseId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                       <div className="loader">Loading...</div>

                     </Modal.Body>
                     <Modal.Footer>
                       </Modal.Footer>


                   </Modal>
                   </div>
                         )
    } else{
      const wareHouseIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouseName === warehouseId);
      const wh = this.props.warehouses.units[wareHouseIndex];
      const { button}  = this.props;
      return (
        <div>
           { this.renderLink(button)}
            <Modal show={this.state.showModal} onHide={this.close}>
              <Modal.Header>
                <Modal.Title>Transfer {this.props.transferProduct} {"[" + this.props.batch + "]"} from {warehouseId}</Modal.Title>
              </Modal.Header>
              <Form inline onSubmit={this.handleOnSubmit}>
              <Modal.Body>
                <div className="inv-top inv-date"><p>Date:</p>
                <DatePicker selected={this.state.endDate} 
                onChange={this.handleChange} 
                dateFormat="dd-MM-yyyy"
                />
              </div>
                <div className="inv-top to-customer">
                  <p>quantity:</p>
                  <FormGroup  controlId="quantity" >
                  <FormControl variant="small" type="number" placeholder={this.state.mQuantity} onChange={this.handleQuantityChange}  />
                </FormGroup>
              </div>
                <div className="inv-top to-customer"><p>to:</p>
                <FormGroup controlId="receiveWareHouse" onChange={this.handleWareHouseChange}>
                 <FormControl as="select" placeholder="select" >
                  <option key='x' value=''>select..</option>;
                    {this.props.warehouses.units.map(this.renderOption)}
                 </FormControl>
               </FormGroup>
             </div>
              </Modal.Body>
              <Modal.Footer>
                <Button  onClick={this.close}>Cancel</Button>
                <Button  onClick={this.handleOnSubmit} variant="primary">Transfer</Button>
              </Modal.Footer>
              </Form>


            </Modal>
            </div>
          )
      }
    }
}

export default TransferWindow;
