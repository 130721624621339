import React from 'react';
import Status from './Status';
import InvoiceList from './InvoiceList';

import { Panel} from 'react-bootstrap';
import WareHouseSummary from './WareHouseSummary';
import InvoiceSummaryHistory from './InvoiceSummaryHistory';
import BrakesInvoiceUploader from './BrakesInvoiceUploader';



class Dashboard extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchWarehouseDashboard('Foulger');
  }


  render() {
    const { warehousedashboard } = this.props;

    if( warehousedashboard.isFetching ){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header="Dashboard">
         <div className="loader">Loading...</div>
        </Panel>
      )
    }else{
      return(
        <Panel className="dashboard" collapsible defaultExpanded header="Dashboard">
          <Status key={warehousedashboard.lastUpdated} warehousedashboard={this.props.warehousedashboard} syncWarehouses={this.props.syncWarehouses} reconcileMatches={this.props.reconcileMatches}/>
          <InvoiceList invoiceList={warehousedashboard.dashboard.missingWarehouseInvoiceList} name="Invoices to update with Warehouse" />
          <InvoiceList invoiceList={warehousedashboard.dashboard.unReconciledInvoiceList} name="Invoices Thast Should Have Been Shipped" />
          <WareHouseSummary {...this.props} warehouse="FDC"  />
          <div>Upload Brakes invoice CSV Count</div><br />
          <BrakesInvoiceUploader />      
          <InvoiceSummaryHistory warehouse="FDC" />
        </Panel>
      )
    }
  }
}



//export default connect(mapStateToProps, mapDispachToProps)(Dashboard)

export default Dashboard;
